import { useContext, useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';

import { Container } from './AccountStyles';
import { AuthContext } from '../../contexts/AuthContexts';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { api } from '../../services/apiClient';
import { parseCookies } from 'nookies';
import { getStripeJs } from '../../services/stripe-js';

export function Account() {
  const { loadingAuth, updateProfile, user, signOut, getMeStatusDetail } =
    useContext(AuthContext);
  const { t } = useTranslation();

  const [name, setName] = useState(user.name || '');
  const [premium, setPremium] = useState(false);
  const [plan, setPlan] = useState('');

  const [amount, setAmount] = useState<number>(0);
  const [loadPurchase, setLoadPurchase] = useState(false);

  function handleChangeAmount(type: 'plus' | 'less') {
    if (type === 'plus') {
      if (amount >= 100) return;

      premium ? setAmount((prev) => prev + 2) : setAmount((prev) => prev + 1);
      return;
    }

    if ((premium && amount <= 0) || (!premium && amount <= 0)) return;
    premium ? setAmount((prev) => prev - 2) : setAmount((prev) => prev - 1);
  }

  const calculateTokenPrice = () => {
    let pricePerToken;
    if (premium) {
      // pricePerToken = 1 / 3;
      pricePerToken = 1 / 2;
    } else {
      // pricePerToken = 1 / 2;
      pricePerToken = 1 / 1;
    }

    const totalPrice = amount * pricePerToken;
    return totalPrice;
  };

  const handlePurchaseTokens = async (event) => {
    event.preventDefault();

    setLoadPurchase(true);

    try {
      const { '@searchvu.token': token } = parseCookies();

      const response = await api.post(
        '/purchase-tokens',
        { tokenAmount: amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { sessionId } = response.data;

      const stripe = await getStripeJs();

      await stripe.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      //console.log('ERROR: ', error);
    } finally {
      setLoadPurchase(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, [user]);

  useEffect(() => {
    setName(user.name || '');
  }, [user.name]);

  async function getInfo() {
    let currentPlan = '';
    const res = {
      status: user?.subscriptions?.status === 'active' ? true : false,
      plan: user?.subscriptions?.plan,
    };
    setPremium(res?.status);
    if (res?.plan && res?.plan !== '') {
      currentPlan = res?.plan === 'premium' ? 'Premium' : 'Premium Plus';
    }
    setPlan(currentPlan);
  }

  function isValidUsername(username) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9._-]{0,29}$/;
    return regex.test(username);
  }

  function handleNameChange(e) {
    const newValue = e.target.value;
    if (newValue === '' || isValidUsername(newValue)) {
      setName(newValue);
    }
  }

  async function sendFormUpdate() {
    if (!name) {
      toast.error(t('auth_alert_required'));
      return;
    }

    if (!isValidUsername(name)) return;

    await updateProfile({ name });
  }

  return (
    <Container>
      <div className="title-area">
        <h2>{t('account_title')}</h2>
        <span></span>
      </div>

      <div className="areaContents">
        <div className="inputArea">
          <label>{t('signup_name')}:</label>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder={t('signup_name')}
          />
          <label>(Email: {user.email})</label>
          <div className="subscriptionArea">
            <p>
              <b>{t('acount_subscription')}: </b>{' '}
              <span>{plan !== '' ? plan : t('plan_free')}</span>
            </p>
            <Link to="/plans">{t('plans_plan_change_plan')}</Link>
          </div>
          <button onClick={() => sendFormUpdate()} disabled={loadingAuth}>
            {!loadingAuth ? t('account_button_update') : <FiLoader size={24} />}
          </button>
          <button onClick={() => signOut()} className="btnSignOut">
            {!loadingAuth ? (
              t('account_button_signout')
            ) : (
              <FiLoader size={24} />
            )}
          </button>
        </div>

        <div className="purchaseArea">
          <h3>{t('token_buy_title')}</h3>
          <p id="subtitle">{t('token_buy_sub')}</p>
          <div className="btnArea">
            <div className="areaAmount">
              <button onClick={() => handleChangeAmount('less')}>-</button>
              <p id="amount">{amount}</p>
              <button onClick={() => handleChangeAmount('plus')}>+</button>
            </div>
            <button
              onClick={handlePurchaseTokens}
              id="btnPurchase"
              disabled={amount <= 0 || loadPurchase}
              style={{
                opacity: amount <= 0 ? '0.7' : '1',
                cursor: amount <= 0 ? 'not-allowed' : 'pointer',
              }}
            >
              {loadPurchase ? (
                <FiLoader size={24} />
              ) : (
                `${t('token_buy_btn')} ($${calculateTokenPrice()})`
              )}
            </button>
          </div>
          <p className="description">{t('currency_information')}</p>
        </div>
      </div>
    </Container>
  );
}
