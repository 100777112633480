import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight, FiLoader, FiSearch } from 'react-icons/fi';

import { Container, ModalOutfit } from './HistoryUserOutfitStyles';
import { useNavigate } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { api } from '../../services/apiClient';
import toast from 'react-hot-toast';
import { CardItem } from '../../components/CardItem/CardItem';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import EmptyOutfitImg from '../../assets/icons/empty_outfit_img.png';
import { AuthContext } from '../../contexts/AuthContexts';

type ResponsePagesTypes = {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
};

export function HistoryUserOutfit() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [isPremium, setIsPremium] = useState(false);

  const [search, setSearch] = useState('');
  const [outfitSelected, setOutfitSelected] = useState<any | null>(null);
  const [loadProducts, setLoadProducts] = useState(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const [allOutfits, setAllOutfits] = useState<any[]>([]);
  const [infoPages, setInfoPages] = useState<ResponsePagesTypes>({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
  });
  const [outfitsData, setOutfitsData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    verifyPremium();
  }, []);

  async function verifyPremium() {
    const isPremium = user.subscriptions?.status === 'active' ? true : false;

    if (!isPremium) {
      navigate('/plans', { state: { premiumFunctionality: true } });
    }

    setIsPremium(isPremium);
  }

  function convertAndAdjustDate(isoDateString) {
    const dateObject = parseISO(isoDateString);

    const currentLanguage = t('current_language');
    let currentLocale = enUS;

    switch (currentLanguage) {
      case 'pt':
        currentLocale = ptBR;
        break;
      case 'es':
        currentLocale = es;
        break;
      default:
        currentLocale = enUS;
    }

    const formattedDate =
      currentLanguage === 'pt'
        ? format(dateObject, 'dd/MM/yyyy hh:mm:ss a', {
            locale: currentLocale,
          })
        : format(dateObject, 'MM/dd/yyyy hh:mm:ss a', {
            locale: currentLocale,
          });
    const timeAgo = formatDistanceToNow(dateObject, {
      addSuffix: true,
      locale: currentLocale,
    });

    return `${formattedDate} (${timeAgo})`;
  }

  async function getHistoryUserOutfits(page = 1, forceUpdate = false) {
    if (!search) {
      toast.error(t('required_user_id'));
      return;
    }

    if (forceUpdate) {
      setOutfitsData({});
      setInfoPages({ currentPage: 1, totalPages: 0, totalRecords: 0 });
    }

    if (!forceUpdate && outfitsData[page]) {
      setAllOutfits(outfitsData[page].results);
      setInfoPages({
        currentPage: outfitsData[page].currentPage,
        totalPages: outfitsData[page].totalPages,
        totalRecords: outfitsData[page].totalRecords,
      });
      return;
    }

    setLoad(true);
    setAllOutfits([]);
    try {
      const { '@searchvu.token': token } = parseCookies();

      const res = await api.post(
        '/history-user-outfit',
        { username: search, page },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data) {
        if (res.data?.msg === 'This user is under protection') {
          toast.error(t('service_history_protection'));
          return;
        }

        if (res.data?.msg === 'This user is VIP') {
          toast.error(t('service_history_vip'));
          return;
        }

        const responseData = res.data?.results;

        if (responseData.length <= 0) {
          toast.error(t('service_history_empty'));
          return;
        }

        // const updatedOutfits = await responseData.map((it) => {
        //   it.outfit = {
        //     products: [...it.outfit],
        //     avatarImage: `https://api.imvu.com/image_dressup/${it.outfit
        //       .map((p) => p.productId)
        //       .join('%2C')}`,
        //   };
        // });

        // let updatedOutfits = [...responseData];

        // try {
        //   const response = await api.post('/get-avatar-image', {
        //     products: responseData.flatMap((outfit) => outfit.products),
        //   });

        //   if (response.data && response.data.avatarImage) {
        //     updatedOutfits = updatedOutfits.map((outfit) => ({
        //       ...outfit,
        //       avatarImage: response.data.avatarImage,
        //     }));
        //   } else {
        //     updatedOutfits = updatedOutfits.map((outfit) => ({
        //       ...outfit,
        //       avatarImage: '',
        //     }));
        //   }
        // } catch (err) {
        //   console.error('Error fetching images:', err);
        //   updatedOutfits = updatedOutfits.map((outfit) => ({
        //     ...outfit,
        //     avatarImage: '',
        //   }));
        // }

        setAllOutfits(res.data.results);
        setInfoPages({
          currentPage: res.data.currentPage,
          totalPages: res.data.totalPages,
          totalRecords: res.data.totalRecords,
        });

        setOutfitsData((prevData) => ({
          ...prevData,
          [page]: {
            // results: res.data.results,
            results: res.data.results,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalRecords: res.data.totalRecords,
          },
        }));
      }
    } catch (error) {
      // console.error(error);
      toast.error(t('service_error'));
    } finally {
      setLoad(false);
    }
  }

  async function getAllProductsInfo(products) {
    setLoadProducts(true);

    try {
      const productIds = products.map((product) => product.product_id);

      const productInfo = await api.post('/get-products-outfit', {
        products: productIds,
      });

      // console.log(productInfo);
      setAllProducts(productInfo.data.products.flat());
    } catch (error) {
      // //console.log('ERROR: ', error);
    } finally {
      setLoadProducts(false);
    }
  }

  function closeUserOutfit() {
    setOutfitSelected(null);
    setAllProducts([]);
  }

  function handlePageChange(newPage) {
    if (newPage < 1 || newPage > infoPages.totalPages) return;
    getHistoryUserOutfits(newPage);
  }

  if (!isPremium) return <></>;

  return (
    <>
      {outfitSelected !== null && (
        <ModalOutfit>
          <button className="btnClose" onClick={closeUserOutfit}>
            {t('serive_history_back_outfit')}
          </button>
          <div className="areaProfile">
            <div className="imageAreaProfile">
              {outfitSelected.outfit.avatarImage ? (
                <img
                  src={outfitSelected.outfit.avatarImage}
                  alt="profile"
                  className="profile"
                />
              ) : (
                <img src={EmptyOutfitImg} alt="profile" className="profile" />
              )}
            </div>
            {loadProducts && (
              <div className="loadArea">
                <FiLoader size={42} color="#f49c14" />
              </div>
            )}
            {!loadProducts && allProducts.length >= 1 && (
              <div className="areaCards">
                {allProducts?.map((item, index) => (
                  <CardItem key={index} {...item} />
                ))}
              </div>
            )}
          </div>
        </ModalOutfit>
      )}
      {outfitSelected === null && (
        <Container>
          <div className="title-area">
            <h2>{t('service_history_outfit_title')}</h2>
            <span></span>
          </div>

          <div className="inputArea">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t('search_user_id')}
            />
            <button
              onClick={() => getHistoryUserOutfits(1, true)}
              disabled={load}
            >
              {load ? <FiLoader size={24} /> : <FiSearch size={24} />}
            </button>
          </div>

          {load && (
            <div className="loadArea">
              <FiLoader size={42} color="#f49c14" />
            </div>
          )}

          {allOutfits.length > 0 && (
            <div className="areaOutfits">
              {allOutfits?.map((it, index) => (
                <div className="outfitItem" key={index}>
                  {it.avatarImage ? (
                    <img src={it.avatarImage} alt={it.outfit_id} />
                  ) : (
                    <img src={EmptyOutfitImg} alt={it.outfit_id} />
                  )}
                  <p className="lastDate">
                    {convertAndAdjustDate(it.created_at)}
                  </p>
                  <button
                    onClick={() => {
                      setOutfitSelected({
                        outfit: {
                          avatarImage: it.avatarImage,
                        },
                      });
                      getAllProductsInfo(it.products);
                    }}
                  >
                    {t('serive_history_more_outfit')}
                  </button>
                </div>
              ))}
            </div>
          )}
          {!load && infoPages.totalPages >= 1 && allOutfits.length > 0 && (
            <div className="areaPages">
              {infoPages.currentPage > 1 && (
                <button
                  onClick={() => handlePageChange(infoPages.currentPage - 1)}
                  disabled={infoPages.currentPage <= 1}
                >
                  <FiArrowLeft />
                </button>
              )}
              <p>
                {infoPages.currentPage} Page of {infoPages.totalPages}
              </p>
              {infoPages.currentPage < infoPages.totalPages && (
                <button
                  onClick={() => handlePageChange(infoPages.currentPage + 1)}
                  disabled={infoPages.currentPage >= infoPages.totalPages}
                >
                  <FiArrowRight />
                </button>
              )}
            </div>
          )}
        </Container>
      )}
    </>
  );
}
