import { useTranslation } from 'react-i18next';
import LOGO from '../../assets/logos/logo_v1_ORIGIN.png';

import { Container } from './FooterStyles';
import { Link } from 'react-router-dom';

export function Footer() {
  const { t } = useTranslation();

  return (
    <Container>
      <img src={LOGO} alt="ExploreVu" />
      <ul>
        <li>
          <Link to="/about">{t('about')}</Link>
        </li>
        <li>
          <Link to="/terms-of-services">{t('terms_service')}</Link>
        </li>
        <li>
          <Link to="/contact">{t('contact')}</Link>
        </li>
      </ul>
      <ul>
        <li>&copy; {t('copyright')}</li>
      </ul>
    </Container>
  );
}
